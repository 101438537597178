import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../logo.svg';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit; /* Inherit the color from the parent */
`;

const ImportWalletPage = () => {
  return (
    <AppContainer>
      <Header>
        <Logo href="#" rel="noopener noreferrer">
          <img src={logo} alt="Phantom Logo" />
        </Logo>
        <HelpLink href="#" rel="noopener noreferrer">
          <span>Help</span>
        </HelpLink>
      </Header>
      <MainContent>
        <Card>
          <BackButton>
            <Link to="/">
              <svg viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
            </Link>
          </BackButton>
          <DotsContainer>
            <Dot active />
            <Dot />
            <Dot />
            <Dot />
          </DotsContainer>
          <Title>Import a wallet</Title>
          <Subtitle>
            Import an existing wallet with your secret phrase, private key, or hardware wallet.
          </Subtitle>
          <StyledLink to="/import-wallet/secret-recovery-phrase">
          <ImportOption>
            <IconContainer>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 14 17">
                <path stroke="#EDEDEF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.333" d="M9.333 1.333H4a1.333 1.333 0 00-1.333 1.333v10.667A1.333 1.333 0 004 14.666h8a1.333 1.333 0 001.333-1.333v-8l-4-4z"></path>
                <path stroke="#EDEDEF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.333" d="M9.334 1.333v4h4M10.667 8.667H5.334M10.667 11.333H5.334M6.667 6H5.333"></path>
              </svg>
            </IconContainer>
            <OptionText>
              <h3>Import Secret Recovery Phrase</h3>
              <p>Import accounts from another wallet</p>
            </OptionText>
          </ImportOption>
          </StyledLink>
          <StyledLink to="/import-wallet/private-recovery-key">
          <ImportOption>
            <IconContainer>
              <svg width="24" height="24" fill="none" viewBox="0 0 14 17">
                <path d="M13 9v2.667A1.334 1.334 0 0 1 11.667 13H2.333A1.334 1.334 0 0 1 1 11.667V9m2.667-3.333L7 9m0 0 3.333-3.333M7 9V1" stroke="#EDEDEF" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </IconContainer>
            <OptionText>
              <h3>Import Private Key</h3>
              <p>Import a single-chain account</p>
            </OptionText>
          </ImportOption>
          </StyledLink>

          <StyledLink to="/import-wallet/ledger-recovery">
          <ImportOption>
            <IconContainer>
            <svg width="24" height="24" fill="none" viewBox="0 0 14 17"><path d="M.184 9.958V12c0 .635.515 1.15 1.15 1.15h3.637a.528.528 0 1 0 0-1.056H2.065a.85.85 0 0 1-.85-.85V9.958a.515.515 0 0 0-1.031 0Zm12.603 0v1.286c0 .47-.38.85-.85.85H9.03a.528.528 0 1 0 0 1.056h3.637a1.15 1.15 0 0 0 1.15-1.15V9.958a.515.515 0 1 0-1.03 0Zm-7.58-5.185v4.32c0 .358.29.65.65.65H8.31a.49.49 0 1 0 0-.982H6.587a.35.35 0 0 1-.35-.35V4.773a.515.515 0 0 0-1.03 0ZM.184 2v2.042a.515.515 0 1 0 1.03 0V2.755c0-.469.381-.85.85-.85h2.907a.528.528 0 0 0 0-1.055H1.334A1.15 1.15 0 0 0 .184 2Zm8.846-.095h2.907c.47 0 .85.381.85.85v1.287a.515.515 0 1 0 1.03 0V2a1.15 1.15 0 0 0-1.15-1.15H9.03a.528.528 0 0 0 0 1.055Z" fill="#EDEDEF" stroke="#fff" stroke-width="0.3"></path></svg>
            </IconContainer>
            <OptionText>
              <h3>Connect Hardware Wallet</h3>
              <p>Use your Ledger hardware wallet</p>
            </OptionText>
          </ImportOption>
          </StyledLink>

        </Card>
      </MainContent>
    </AppContainer>
  );
};

export default ImportWalletPage;

// Styled Components
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #e2dffd;
`;

const Header = styled.header`
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.a`
  img {
    width: 40px;
    height: 40px;
  }
`;

const HelpLink = styled.a`
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;

  span {
    margin-left: 8px;
    color: #333;
  }
`;

const MainContent = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const Card = styled.div`
  background-color: #222;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  min-width: 300px;
`;

const BackButton = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  svg {
    stroke: #fff;
  }
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: -10px;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${(props) => (props.active ? '#8a68ef' : '#555')};
  border-radius: 50%;
  margin: 0 5px;
`;

const Title = styled.h2`
  color: #fff;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  color: #999;
  margin-bottom: 30px;
`;

const ImportOption = styled.div`
  display: flex;
  text-align: left;
  background-color: #444;
  padding: 0px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    background-color: #555;
  }
`;

const IconContainer = styled.div`
  padding: 15px;
  margin-top:7px;

`;

const OptionText = styled.div`
  h3 {
    color: #fff;
    font-size: 16px;   
  }

  p {
    color: #777;
    margin-top:-15px;
    font-size: 13px;
  }
`;
