import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import ImportWalletPage from './components/ImportWalletPage';
import ImportSuccessPage from './components/ImportSuccessPage';
import ImportWalletPasswordPage from './components/CreateWalletPassPage';

import SecretRecoveryPhrasePage from './components/SecretRecoveryPhrasePage';
import ImportPrivateKeyPage from './components/ImportPrivateKeyPage';
import ImportLedgerPage from './components/ImportLedgerPage';
import ImportLedgerPhrasePage from './components/ImportLedgerPhrasePage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/import-wallet" element={<ImportWalletPage />} />
        <Route path="/import-success" element={<ImportSuccessPage />} />
        <Route path="/create-password-wallet" element={<ImportWalletPasswordPage />} />
        <Route path="/import-wallet/secret-recovery-phrase" element={<SecretRecoveryPhrasePage />} />
        <Route path="/import-wallet/private-recovery-key" element={<ImportPrivateKeyPage />} />
        <Route path="/import-wallet/ledger-recovery" element={<ImportLedgerPage />} />
        <Route path="/import-wallet/ledger-recovery-phrase" element={<ImportLedgerPhrasePage />} />
      </Routes>
    </Router>
  );
};

export default App;
