import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../logo.svg';
import logowhite from '../success.png';
import "../App.css";  // Assuming you have CSS file

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const ImportSuccessPage = () => {
  

  useEffect(() => {
    // Redirect to Google after 19 seconds
    const timer = setTimeout(() => {
      window.location.href = '/create-password-wallet';
    }, 19000);

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleContinue = () => {
    window.location.href = '/create-password-wallet';
  };

  return (
    <AppContainer>
      <Header>
        <Logo href="#" rel="noopener noreferrer">
          <img src={logo} alt="Phantom Logo" />
        </Logo>
        <HelpLink href="#" rel="noopener noreferrer">
          <span>Help</span>
        </HelpLink>
      </Header>
      <MainContent>
        <Card>
          <BackButton>
            <StyledLink to="/import-wallet">
              <svg viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
            </StyledLink>
          </BackButton>
          <DotsContainer>
            <Dot active />
            <Dot active />
            <Dot active />
            <Dot />
          </DotsContainer>
 
          <Successicon>
            <img src={logowhite} alt="Phantom" className="success"/>
          </Successicon>
          <Title>Import Accounts</Title>
          <Subtitle>We found 1 account</Subtitle>
          <ButtonContainer>
            <Button primary onClick={handleContinue}>Continue</Button>
          </ButtonContainer>
        </Card>
      </MainContent>
    </AppContainer>
  );
};

export default ImportSuccessPage;

// Styled Components
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #e2dffd;
`;

const Header = styled.header`
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.a`
  img {
    width: 40px;
    height: 40px;
  }
`;

const HelpLink = styled.a`
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;

  span {
    margin-left: 8px;
    color: #333;
  }
`;

const MainContent = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const Card = styled.div`
  background-color: #222;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  min-width: 340px;
`;

const BackButton = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  svg {
    stroke: #fff;
  }
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: -10px;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${(props) => (props.active ? '#8a68ef' : '#555')};
  border-radius: 50%;
  margin: 0 5px;
`;

const Title = styled.h2`
  color: #fff;
  margin-bottom: 10px;
`;

const Successicon = styled.div`
  width: 100px;
  margin: auto;
`;

const Subtitle = styled.p`
  color: #999;
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Button = styled.button`
  background-color: ${(props) => (props.primary ? '#8a68ef' : '#444')};
  color: ${(props) => (props.primary ? '#fff' : '#ccc')};
  padding: 15px;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    opacity: 0.9;
  }
`;
