import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../logo.svg';
import logowhite from '../logo-white.svg';

const HomePage = () => {
  return (
    <AppContainer>
      <Header>
        <Logo href="#" rel="noopener noreferrer">
          <img src={logo} alt="Phantom Logo" />
        </Logo>
        <HelpLink href="#" rel="noopener noreferrer">
          <span>Help</span>
        </HelpLink>
      </Header>
      <MainContent>
        <Card>
          <LogoContainer>
            <img src={logowhite} alt="Phantom" />
          </LogoContainer>
          <Subtitle>
            To get started, create a new wallet or import an existing one.
          </Subtitle>
          {/* <Button primary>Create a new wallet</Button> */}
          <Link to="/import-wallet">
            <Button primary>Import an existing wallet</Button>
          </Link>
        </Card>
      </MainContent>
    </AppContainer>
  );
};

export default HomePage;

// Styled Components
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #e2dffd;
`;

const Header = styled.header`
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.a`
  img {
    width: 40px;
    height: 40px;
  }
`;

const HelpLink = styled.a`
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;

  span {
    margin-left: 8px;
    color: #333;
  }
`;

const MainContent = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const Card = styled.div`
  background-color: #222;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;

  img {
    width: 255px;
  }
`;

const Subtitle = styled.p`
  color: #999;
  margin-bottom: 30px;
`;

const Button = styled.button`
  background-color: ${(props) => (props.primary ? '#8a68ef' : '#444')};
  color: ${(props) => (props.primary ? '#fff' : '#ccc')};
  padding: 15px;
  width: 100%;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight:bold;
  &:hover {
    opacity: 0.9;
  }
`;
